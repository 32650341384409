<template>
  <main>
    <!-- ===== Hero ===== -->
    <div class="bg-img-hero position-relative overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-solution-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-lg-65 text-center mx-lg-auto">
          <img class="img-fluid mb-5" src="@/assets/img/products/fabrixai-logo.svg" style="max-width:15rem">
          <h1 class="mb-3">Simplify Automation with Low-Code AI Agents</h1>
          <p class="lead">A comprehensive platform for creating powerful AI agents and effortlessly linking them to handle more complex tasks.</p>
          <a class="btn btn-primary transition-3d-hover mt-3" href="https://www.fabrixai.com/" target="_blank">Learn More <i class="fas fa-external-link-alt ml-1" /></a>
        </div>
        <!-- Video -->
        <div class="position-relative w-90 w-md-75 mx-auto mt-6">
          <div class="card overflow-hidden mx-lg-auto bg-transparent">
            <a href="javascript:;" data-toggle="modal" data-target="#videoModal" class="video-player">
              <img class="img-fluid video-player-preview" src="@/assets/img/products/fabrixai-video-cover.jpg">
              <div class="video-player-btn video-player-centered">
                <span class="video-player-icon video-player-icon-pill video-player-icon-primary">
                  <i class="fas fa-play-circle mr-2 font-size-2" /> Watch the Video
                </span>
              </div>
              <div class="embed-responsive embed-responsive-16by9">
                <div id="oahVideoIframe" />
              </div>
            </a>
          </div>
          <div id="videoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle" aria-hidden="true">
            <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 id="videoModalTitle" class="modal-title" />
                  <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                      <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                    </svg>
                  </button>
                </div>
                <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                  <div style="padding:52% 0 0 0;position:relative;">
                    <iframe src="https://player.vimeo.com/video/1003996363?h=2b42a8c422&amp;badge=0&amp;autopause=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; loop;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="FabriXAPI - What is API Portal" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="position-relative w-90 w-md-75 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/img/products/fiberconnect/fiber-hero-img.png" alt="FiberConnect Portals">
          </div>
        </div> -->
      </div>
    </div>
    <!-- ===== End Hero ===== -->

    <!-- ===== Features ===== -->
    <div class="container space-2 space-lg-3">
      <!-- Feature 1 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">Build Supportive AI Agents with Ease</h2>
            <p>FabriXAI empowers users to create AI agents effortlessly, utilizing a low-code environment that requires minimal technical expertise. With intuitive templates and pre-built tools, anyone can develop customized AI solutions tailored to their specific needs.</p>
            <h5 class="mb-3">Benefits</h5>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Choose from a variety of templates to suit different business functions, ensuring relevance and efficiency
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Rapidly deploy AI agents, reducing development time and accelerating project timelines
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Empower your team to create and manage AI solutions without relying heavily on IT resources
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-90 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/ai-agent.svg" alt="Build Supportive AI Agents with Ease">
          </div>
        </div>
      </div>
      <!-- Feature 2 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6 order-lg-2">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">Chain AI Agents to Achieve Greater Results</h2>
            <p>Maximize productivity by linking multiple AI agents to create sophisticated workflows. FabriXAI enables seamless connections between agents, allowing them to collaborate and handle complex tasks collectively.</p>
            <h5 class="mb-3">Benefits</h5>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Automate multi-step processes, reducing manual intervention and errors
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Ensure that each AI agent performs optimally according to its strength
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Monitor and analyze the performance of interconnected agents to refine workflows
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 order-lg-1" data-aos="fade-right">
          <div class="w-90 w-md-75 w-lg-90 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/agentic-workflow.svg" alt="Chain AI Agents to Achieve Greater Results">
          </div>
        </div>
      </div>
      <!-- Feature 3 -->
      <div class="row align-items-lg-center">
        <div class="col-lg-6 col-xl-6 mb-6 mb-lg-0">
          <h2 class="mb-3">Embed Your AI Agents Anywhere</h2>
          <p>FabriXAI facilitates the integration of AI agents into any application or workflow, allowing businesses to leverage AI capabilities in their existing systems. This flexibility ensures that automation is not limited to standalone applications.</p>
          <h5 class="mb-3">Benefits</h5>
          <div class="media text-body font-size-1 mb-3">
            <i class="fas fa-star text-warning mt-1 mr-2" />
            <div class="media-body">
              Embed AI agents into your favorite applications effortlessly, enhancing functionality without disruption
            </div>
          </div>
          <div class="media text-body font-size-1 mb-3">
            <i class="fas fa-star text-warning mt-1 mr-2" />
            <div class="media-body">
              Tailor the AI experience to fit your specific operational needs, creating a cohesive user experience
            </div>
          </div>
          <div class="media text-body font-size-1 mb-3">
            <i class="fas fa-star text-warning mt-1 mr-2" />
            <div class="media-body">
              Embed AI capabilities directly into customer-facing applications, improving user interaction and satisfaction
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-90 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/seamless-embed.svg" alt="Embed Your AI Agents Anywhere">
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End Features ===== -->

    <!-- ===== CTA Section ===== -->
    <div class="bg-img-hero position-relative overflow-hidden text-center" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2">
        <div class="mb-6">
          <h2>Let's Simplify Your Workflow with AI Agents</h2>
          <p>Interested in accelerating your processes? Explore the powerful features of FabriXAI now!</p>
        </div>
        <a class="btn btn-primary transition-3d-hover" href="https://www.fabrixai.com/" target="_blank">Explore FabriXAI <i class="fas fa-external-link-alt ml-1" /></a>
      </div>
    </div>
    <!-- ===== End CTA Section ===== -->

    <!-- ===== Other Products Section ===== -->
    <div class="container space-2 space-lg-3">
      <div class="mb-5 text-center">
        <h2>More by beNovelty</h2>
      </div>
      <div class="row" data-aos="fade-up">
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixapi' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fabrixapi-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>API Management Platform</h3>
              <p><strong>FabriXAPI</strong> is a one-stop API platform for providers to build and share APIs.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/openapihub' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-openapihub-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>Leading API Community</h3>
              <p><strong>OpenAPIHub</strong> is the leading API community with a powerful API directory and ecosystem.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fiberconnect' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fiberconnect-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>API Connectivity for Financial Services</h3>
              <p><strong>FiberConnect</strong> uses Open API to boost SME & Startup efficiency with QR Code payments.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/taptab' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-taptab-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>AI Agents for Education</h3>
              <p><strong>TapTab</strong> is the AI agents designed for the education sector.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- ===== End Other Products Section ===== -->

  </main>
</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import HSVideoPlayer from '@/assets/vendor/hs-video-player/src/js/hs-video-player'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import { mapGetters } from 'vuex'
export default {
  name: 'FabriXAI',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-inline-video-player').each(function() {
      new HSVideoPlayer($(this)).init()
    })
    $('#videoModal').on('hidden.bs.modal', function(e) {
      $('#videoModal iframe').attr('src', $('#videoModal iframe').attr('src'))
    })
  },
  created() {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'FabriXAI | Products | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/products/fabrixai' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'FabriXAI | Products | beNovelty' },
        { property: 'og:description', content: 'Unlock the power of generative AI with FabriXAI—the Next-Gen AI Agents Development Platform.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/products/fabrixai' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<style>
.video-player-icon-primary{
  color: #ffffff;
  background-color: #57abf0;
}

.video-player-btn:hover .video-player-icon-primary {
  color: #ffffff;
  background-color: #57abf0;
  box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.video-player-icon-pill {
  width: 100%;
  min-width: 13rem;
  height: 100%;
  padding: 0.75rem 1rem;
  border-radius: 3.125rem;
  font-size: 1rem;
  font-weight: 600;
}
</style>

